<template>
    <transition appear @enter="onEnter">
        <svg
            id="eggplant"
            ref="eggplant"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 143.24 172.45"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2587532658496">
                    <g ref="heroBody">
                        <path
                            class="hero_body"
                            fill="#4C4987"
                            fill-rule="nonzero"
                            d="M116.47 103.82c-0.41,-3.98 -1.09,-7.95 -2.19,-11.8 -0.42,-1.46 -0.91,-2.91 -1.51,-4.3 -0.73,-1.67 -1.75,-3.18 -2.57,-4.79 -3.47,-6.76 -5.8,-14.13 -7.8,-21.44 -0.97,-3.51 -1.83,-7.06 -2.55,-10.64 -0.52,-2.6 -1,-5.25 -1.15,-7.9 -0.1,-1.73 0.07,-3.45 0.18,-5.17 0.16,-2.59 0.22,-5.18 -0.23,-7.74 -1.52,-7.99 -8.5,-13.56 -16.14,-15.36 -4.83,-1.14 -9.61,-0.63 -14.11,1.44 -2.19,1.01 -4.4,1.99 -6.51,3.18 -1.64,0.91 -3.21,1.98 -4.48,3.38 -1.31,1.43 -2.24,3.14 -2.91,4.95 -0.95,2.56 -1.47,5.25 -1.9,7.94 -0.65,4.13 -1.04,8.31 -1.41,12.48 -0.84,9.38 -3.02,18.57 -5.59,27.61 -2.38,8.38 -5.19,16.62 -7.67,24.97 -1.58,5.32 -3.09,10.68 -3.15,16.26 -0.04,3.02 -0.12,6.04 0.31,9.04 0.49,3.39 1.64,6.65 3.62,9.46 2.61,3.72 6.42,6.41 10.46,8.4 6.56,3.24 13.89,4.88 21.08,6 7.31,1.13 14.89,2.08 22.21,0.5 4.94,-1.07 9.59,-3.31 13.3,-6.77 3.42,-3.19 5.93,-7.24 7.64,-11.58 1.76,-4.48 2.73,-9.24 3.17,-14.02 0.43,-4.69 0.37,-9.42 -0.1,-14.1z"
                        />
                        <g ref="stalk">
                            <path
                                class="hero_body stalk"
                                fill="#699895"
                                fill-rule="nonzero"
                                d="M99.16 26.69c-0.3,-2.31 -0.27,-4.66 -0.73,-6.94 -0.05,-0.27 -0.13,-0.52 -0.22,-0.78l-0.02 -0.04 0 0c-0.39,-1.42 -1.09,-2.73 -1.99,-3.89 -2.03,-2.61 -4.97,-4.34 -8.09,-5.36 -2.62,-0.85 -5.33,-1 -8.02,-1.48 -5.06,-0.92 -10.62,-0.75 -15.35,1.41 -3.22,1.47 -5.87,3.91 -7.57,7.01 -0.75,1.37 -1.16,2.83 -1.77,4.26 -0.61,1.43 -1.25,2.86 -1.92,4.27 -0.78,1.66 -1.57,3.39 -2.65,4.87 -0.43,0.59 -0.86,1.08 -1.03,1.82 -0.19,0.82 -0.09,1.69 0.21,2.47 0.65,1.65 2.22,2.85 4.03,2.83 1.1,-0.02 2.13,-0.62 2.97,-1.29 2.43,-1.93 4.19,-4.7 5.91,-7.24 0.83,-1.24 1.64,-2.49 2.52,-3.7 0.43,-0.58 1.12,-1.77 1.88,-1.99 0.59,-0.18 0.74,0.87 0.8,1.3 -0.15,1.71 -0.16,3.44 -0.1,5.16 0.09,3.01 0.4,6.21 1.68,8.98 1.05,2.28 3.23,4.15 5.9,3.44 2.37,-0.63 3.34,-3.21 3.89,-5.35 0.74,-2.88 0.94,-5.87 1.46,-8.8 0.27,-1.47 0.7,-4.94 2.8,-4.89 0.61,0.01 1.19,0.28 1.72,0.56 1.18,0.63 2.26,1.45 3.22,2.38 1.61,1.58 2.83,3.49 4.4,5.1 1.33,1.37 3.05,2.57 4.95,1.53 0.82,-0.45 1.45,-1.24 1.65,-2.16 0.25,-1.16 -0.38,-2.33 -0.53,-3.48z"
                            />
                            <path
                                class="hero_body stalk"
                                fill="#699895"
                                fill-rule="nonzero"
                                d="M78.93 0.45c-0.77,1.47 -1.41,3.05 -1.84,4.65 -0.57,2.09 -0.97,5.08 1.18,6.44 1.21,0.78 2.24,-0.93 1.08,-1.68 -0.33,-0.2 -0.37,-0.23 -0.48,-0.54 -0.12,-0.29 -0.16,-0.71 -0.15,-1.09 0.01,-0.98 0.26,-1.94 0.54,-2.87 0.41,-1.32 0.9,-2.52 1.46,-3.59 0.57,-1.11 -1.18,-2.5 -1.79,-1.32z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            class="fil2"
                            fill="F2D196"
                            fill-rule="nonzero"
                            d="M56.96 87.52c-0.04,0.39 -0.12,0.79 -0.19,1.17 -0.07,0.32 -0.24,0.72 -0.1,1.04 0.05,0.12 0.18,0.16 0.29,0.09 0.32,-0.2 0.37,-0.68 0.41,-1.02 0.06,-0.43 0.03,-0.83 -0.03,-1.26 -0.03,-0.2 -0.36,-0.25 -0.38,-0.02z"
                        />
                        <path
                            class="fil2"
                            fill="F2D196"
                            fill-rule="nonzero"
                            d="M59.97 84.35c-0.17,0.42 -0.32,0.87 -0.45,1.31 -0.11,0.38 -0.35,0.86 -0.2,1.25 0.05,0.12 0.21,0.19 0.33,0.1 0.32,-0.25 0.35,-0.71 0.45,-1.09 0.12,-0.47 0.23,-0.95 0.3,-1.44 0.04,-0.24 -0.33,-0.38 -0.43,-0.13z"
                        />
                        <path
                            class="fil2"
                            fill="F2D196"
                            fill-rule="nonzero"
                            d="M60.47 88.91c-0.19,0.4 -0.4,0.78 -0.61,1.16 -0.19,0.35 -0.44,0.65 -0.44,1.06 0.01,0.09 0.12,0.16 0.2,0.12 0.41,-0.16 0.57,-0.57 0.76,-0.94 0.2,-0.41 0.34,-0.84 0.45,-1.28 0.06,-0.22 -0.27,-0.31 -0.36,-0.12z"
                        />
                        <g ref="eyes">
                            <path
                                ref="eyeRightWhite"
                                class="eye eye_right white"
                                fill="white"
                                fill-rule="nonzero"
                                d="M93.98 90.88c0,4.29 -3.48,7.77 -7.77,7.77 -4.29,0 -7.76,-3.48 -7.76,-7.77 0,-4.29 3.47,-7.77 7.76,-7.77 4.29,0 7.77,3.48 7.77,7.77z"
                            />
                            <path
                                ref="eyeLeftWhite"
                                class="eye eye_left white"
                                fill="white"
                                fill-rule="nonzero"
                                d="M70.92 90.4c0,4.29 -3.48,7.77 -7.77,7.77 -4.29,0 -7.76,-3.48 -7.76,-7.77 0,-4.29 3.47,-7.76 7.76,-7.76 4.29,0 7.77,3.47 7.77,7.76z"
                            />
                            <path
                                ref="eyeRight"
                                class="eye eye_right"
                                fill="#283039"
                                fill-rule="nonzero"
                                d="M88.75 91.84c0,2.48 -2.01,4.49 -4.49,4.49 -2.48,0 -4.49,-2.01 -4.49,-4.49 0,-2.48 2.01,-4.49 4.49,-4.49 2.48,0 4.49,2.01 4.49,4.49z"
                            />
                            <path
                                ref="eyeLeft"
                                class="eye eye_left"
                                fill="#283039"
                                fill-rule="nonzero"
                                d="M68.99 92.15c0,2.48 -2.02,4.49 -4.49,4.49 -2.48,0 -4.49,-2.01 -4.49,-4.49 0,-2.48 2.01,-4.49 4.49,-4.49 2.47,0 4.49,2.01 4.49,4.49z"
                            />
                        </g>
                        <g ref="glasses">
                            <path
                                class="glasses"
                                fill="#FF9999"
                                fill-rule="nonzero"
                                d="M97.62 94.69c-2.4,5.73 -10.23,8.13 -15.69,5.37 -5.07,-2.57 -7.5,-9.47 -4.44,-14.25 0.38,-0.59 1.97,-8 12.77,-7.06 7.04,0.62 9.98,9.69 7.36,15.94zm-6.1 -17.05c-13.07,-1.75 -15.76,9.18 -15.71,9.26 -2.4,5.51 0.09,12.3 5.72,14.85 6.28,2.86 15.14,0.21 17.77,-6.48 2.82,-7.18 0.22,-16.57 -7.78,-17.63z"
                            />
                            <path
                                class="glasses"
                                fill="#FF9999"
                                fill-rule="nonzero"
                                d="M69.34 94.33c-2.4,5.73 -10.23,8.14 -15.69,5.37 -5.06,-2.56 -7.5,-9.47 -4.44,-14.25 0.38,-0.59 1.97,-8 12.77,-7.05 7.04,0.61 9.98,9.69 7.36,15.93zm-6.1 -17.05c-13.07,-1.75 -15.76,9.18 -15.71,9.27 -2.4,5.5 0.09,12.29 5.72,14.85 6.28,2.85 15.14,0.2 17.77,-6.49 2.83,-7.18 0.22,-16.56 -7.78,-17.63z"
                            />
                            <path
                                class="glasses"
                                fill="#FF9999"
                                fill-rule="nonzero"
                                d="M109.74 74.54c-1.87,1.16 -3.59,2.78 -5.25,4.21 -2.04,1.73 -4.26,3.46 -5.78,5.68 -0.32,0.46 0.3,1.04 0.76,0.76 2.13,-1.33 3.89,-3.23 5.8,-4.85 0.79,-0.67 7.92,-6.96 8.19,-3.95 0.11,1.22 2.03,1.22 1.91,0 -0.31,-3.2 -3.38,-3.24 -5.63,-1.85z"
                            />
                            <path
                                class="glasses"
                                fill="#FF9999"
                                fill-rule="nonzero"
                                d="M50.85 75.67c-0.57,-1.64 -1.35,-3.48 -2.94,-4.38 -2.81,-1.57 -6.49,-0.42 -5.65,3.27 0.18,0.79 1.54,0.86 1.66,0 0.02,-0.08 0.09,-1.12 0.12,-1.17 0.38,-0.68 1.57,-0.86 2.23,-0.73 1.3,0.24 1.97,1.5 2.46,2.58 0.47,1.03 0.81,2.14 1.06,3.25 0.21,0.97 0.19,1.75 0.82,2.53 0.2,0.26 0.63,0.16 0.77,-0.1 0.84,-1.46 -0.02,-3.77 -0.53,-5.25z"
                            />
                            <path
                                class="glasses"
                                fill="#FF9999"
                                fill-rule="nonzero"
                                d="M74.83 89.98c-0.92,-0.21 -2.66,-0.42 -3.07,0.69 -0.07,0.2 0.01,0.46 0.15,0.6 0.36,0.32 0.75,0.29 1.21,0.3 0.43,0.01 0.81,0.08 1.21,0.22 1.14,0.39 1.68,-1.53 0.5,-1.81z"
                            />
                        </g>
                        <path
                            ref="mouth"
                            class="mouth"
                            fill="#13161A"
                            fill-rule="nonzero"
                            d="M77.08 116.24c-0.02,0.25 -0.14,0.5 -0.39,0.65 -1.28,0.74 -3.01,0.29 -4.39,-0.53 -0.5,-0.24 -0.96,-0.56 -1.32,-0.97 -0.12,-0.11 -0.23,-0.21 -0.32,-0.32 -0.19,-0.2 -0.27,-0.41 -0.28,-0.62 -0.01,-0.03 -0.03,-0.06 -0.05,-0.1 -0.17,-0.45 0.32,-0.89 0.75,-0.83 0.29,-0.09 0.62,-0.05 0.93,0.2 0.54,0.44 1.09,0.89 1.72,1.21 0.02,0.01 0.04,0.02 0.06,0.03 0.67,0.18 1.37,0.2 2.19,-0.07 0.57,-0.19 1.19,0.31 1.17,0.89 0,0.17 -0.03,0.32 -0.07,0.46z"
                        />
                    </g>
                    <path
                        ref="legRight"
                        class="leg leg_right"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M110.88 158.38c-5.65,0.17 -12.84,1.02 -18.28,3.89 -0.75,-0.01 -1.46,0.03 -2.1,0.11 0.19,-3.1 -0.1,-6.37 -0.08,-9.24 0.03,-2.56 0.84,-7.18 -0.99,-9.23 -0.63,-0.7 -2.02,-0.56 -2.43,0.31 -0.03,0.05 -0.05,0.1 -0.07,0.14 -0.23,0.48 -0.22,0.98 0.01,1.41 0.98,3.18 0.3,7.65 0.34,10.89 0.03,2.36 0.18,10.11 0.66,10.33 0.05,0.16 25.29,2.81 27.7,3.02 3.38,0.29 11.09,0.28 11.17,-4.66 0.12,-6.8 -11.7,-7.09 -15.93,-6.97z"
                    />
                    <path
                        ref="legLeft"
                        class="leg leg_left"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M58.34 144.56c-2.25,6.25 -2.86,13.23 -2.37,19.89 -6.75,-2.47 -14.28,-2.69 -21.32,-1.62 -2.78,0.43 -12.25,1.39 -11.57,6.37 0.51,3.77 6.01,3.28 8.58,3.16 8.22,-0.39 16.7,-1.53 24.59,-3.86 0.37,-0.11 2.38,-0.9 2.45,-1.89 0.52,-7.28 0.12,-14.23 2.74,-21.2 0.72,-1.93 -2.4,-2.79 -3.1,-0.85z"
                    />
                    <g ref="arms">
                        <path
                            ref="armLeft"
                            class="arm arm_left"
                            :class="{ say_hi: animationIs === 'sayHi' }"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M40.41 104.6c-9.8,-1.67 -17.83,-5.49 -25.46,-10.58 -1.69,-0.94 -1.91,-1.73 -1.91,-1.73 -0.33,-3.49 -1.13,-7.28 -3.28,-9.87 -0.44,-0.53 -1.49,-0.12 -1.41,0.58 0.2,1.79 0.85,3.45 1.28,5.19 0.19,0.8 0.34,1.61 0.47,2.42 -2,-1.49 -3.99,-3.05 -5.99,-4.68 -0.95,-0.77 -2.1,0.38 -1.33,1.32 1.86,2.31 3.99,4.4 6.3,6.3 -2.67,0.49 -5.33,0.29 -8.26,0.03 -0.84,-0.07 -1.12,1.18 -0.42,1.56 3.31,1.82 7.35,1.69 10.96,0.6 0.23,0.1 0.49,0.16 0.74,0.13 8.18,5.8 18.16,9.53 27.55,11.49 1.82,0.38 2.56,-2.45 0.76,-2.76z"
                        />
                        <path
                            ref="armRight"
                            class="arm arm_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M124.86 100.69c-5.43,-2.53 -12.85,-2.05 -17.2,2.3 -0.79,0.8 0.36,2.19 1.32,1.73 5.54,-2.64 11.11,-4.59 16.74,-0.73 2.43,1.66 4.43,4.14 5.48,6.89 0.89,2.34 0.4,4.96 1.31,7.2 0.04,0.09 0.1,0.16 0.17,0.24 -1.8,2.46 -3.19,6.53 -1.83,9.26 0.37,0.75 1.42,0.62 1.58,-0.21 0.3,-1.46 0.09,-2.94 0.51,-4.41 0.29,-1.03 0.75,-1.95 1.3,-2.82 0.33,0.78 0.7,1.55 0.97,2.28 0.58,1.58 0.98,3.31 1.89,4.72 0.35,0.55 1.38,0.3 1.38,-0.37 0.03,-1.66 -0.59,-3.33 -1.1,-4.9 -0.27,-0.83 -0.53,-1.8 -0.86,-2.71 0.64,0.29 1.24,0.67 1.87,1.22 1.27,1.14 2.19,2.56 3.37,3.79 0.65,0.68 1.71,0.11 1.43,-0.82 -0.91,-3.03 -5.36,-8.04 -8.97,-6.82 -0.7,-3.29 -0.27,-6.15 -2.24,-9.4 -1.68,-2.78 -4.17,-5.06 -7.12,-6.44z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </transition>
</template>
<script>
import { gsap } from "gsap";
export default {
    name: "Eggplant",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },

        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },

    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
            gsap.set(this.$refs.heroBody, { transformStyle: "preserve-3d" });
            tl.to(this.$refs.eyes, {
                y: -20,
                x: -20,
                scale: 2,
                duration: 0.5,
                ease: "bounce.out",
                z: 30,
            })
                .to(this.$refs.eggplant, { rotationY: 35, duration: 0.1 }, 0)
                .to(
                    this.$refs.glasses,
                    {
                        x: -15,
                        y: -20,
                        scale: 1.4,
                        duration: 0.08,
                    },
                    0
                )
                .to(this.$refs.arms, { scale: 0.8, x: 5, duration: 0 }, 0)
                .to(this.$refs.stalk, { y: -25, duration: 0.1 }, 0)
                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )
                .to(this.$refs.stalk, { y: 0, rotate: 0, duration: 0.1 }, 0.7)
                .to(
                    this.$refs.glasses,
                    { x: 0, y: 0, scale: 1, duration: 0.1 },
                    0.7
                )
                .to(
                    this.$refs.eggplant,
                    { rotationY: 0, skewX: 0, scaleX: 1, duration: 0.8 },
                    0.7
                )
                .to(this.$refs.arms, { scale: 1, x: 0, duration: 0.1 }, 0.7);
        },
        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.glasses, { transformStyle: "preserve-3d" });
            gsap.set(this.$refs.legLeft, { transformOrigin: "right top" });
            gsap.set(this.$refs.armLeft, {
                transformOrigin: "right",
                rotate: 0,
            });

            tl.to(
                this.$refs.legRight,
                {
                    rotate: 35,
                    duration: 0.3,
                },
                0
            )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: -25,
                        duration: 0.3,
                    },
                    0
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: -70,
                        x: -10,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 70,
                        x: 10,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.eggplant,
                    {
                        y: -30,
                        duration: 0.8,
                    },
                    0
                )
                .to(
                    this.$refs.glasses,
                    {
                        y: -10,
                    },
                    0
                )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.legRight,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.glasses,
                    {
                        y: 0,
                        duration: 0.1,
                    },
                    0.7
                )
                .to(
                    this.$refs.eggplant,
                    {
                        y: 0,
                        duration: 0.3,
                    },
                    0.7
                );
        },
        sad() {
            var tl = gsap.timeline({ repeat: -1 });

            gsap.set(this.$refs.mouth, {
                rotate: "180deg",
                translateX: 10,
                translateY: 10,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 50,
                translateX: -5,
            });
        },
    },
};
</script>

<style scoped>
* {
    overflow: visible;
}
.say_hi {
    transform-origin: bottom right;
    animation: hi infinite 4s ease-in-out;
    transform: rotate(30deg) translateX(-30px) translateY(50px);
}

@keyframes hi {
    0% {
        transform: rotate(30deg) translateX(-25px) translateY(50px);
    }

    5% {
        transform: rotate(35deg) translateX(-25px) translateY(50px);
    }

    10% {
        transform: rotate(30deg) translateX(-25px) translateY(50px);
    }

    90% {
        transform: rotate(30deg) translateX(-25px) translateY(50px);
    }

    95% {
        transform: rotate(35deg) translateX(-25px) translateY(50px);
    }

    100% {
        transform: rotate(30deg) translateX(-25px) translateY(50px);
    }
}
</style>
